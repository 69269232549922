body {
  background-color: white !important;
}

// Custom classes

// --------------- Fonts ---------------
.font-primary {
  font-family: $font-family-primary !important;
}

.font-secondary {
  font-family: $font-family-secondary !important;
}

label {
  color: $primary !important;
}

// --------------- Text ---------------

.text-justify {
  text-align: justify !important;
}

.no-wrap {
  white-space: nowrap;
}

// --------------- Buttons ---------------

.btn {
  transition: 0.5s;
}

.CompanyBtnStyling {
  color: #3e6a9f;
}

.white-primary {
  background-color: white !important;
  color: $primary !important;
  transition: 0.5s;

  &:hover {
    transform: scale(1.05);
  }
}

.heading-background {
  background: #f2f2f2;
  padding: 5px 6px;
  border-radius: 5px;
  border-left: 5px solid #3e6a9f;
}

.css-b62m3t-container {
  .css-1s2u09g-control {
    background-color: $card-bg;
    border-color: $border-color;

    &:hover {
      border-color: $border-color;
    }
  }

  .css-1pahdxg-control {
    background-color: $card-bg;
    border-color: $border-color;
    box-shadow: none;

    &:hover {
      border-color: $border-color !important;
    }
  }

  .css-26l3qy-menu {
    background-color: $dropdown-bg;

    .css-1n7v3ny-option {
      cursor: pointer;
      background-color: $dropdown-link-hover-bg;

      &:active {
        background-color: $dropdown-link-hover-bg;
      }
    }
  }

  .css-1rhbuit-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }

  .css-12jo7m5 {
    color: $heading-color;
  }

  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

.page-wrapper {
  margin-top: calc(2 * $header-height);
}

.inner-landing-container {
  margin-top: $header-height;
}

.services-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover !important;
  height: calc(100vh - $header-height);
  padding: 0 50px;

  .inner {
    height: 65%;
    width: 100;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  h1 {
    font-family: $font-family-primary;
  }

  &.hiring {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../../images/landing-bg/get-hired.jpeg");
    background-position: center;
  }

  &.get-hired {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../../images/landing-bg/hiring.jpeg");
    background-position: center;

    i {
      margin-right: 5px;
    }
  }

  &.outsourcing {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url("../../../images/landing-bg/outsourcing.webp");
    background-position: center;
  }

  @media (max-width: 768px) {
    height: 50vh;

    &.hiring,
    &.outsourcing {
      margin-top: 2px;
    }
  }
}

span.badge {
  font-size: 0.8rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.form-select {
  padding: 0.8rem 0.9rem !important;
}

.input-group-text {
  border-color: rgba($primary, 0.5) !important;
}

select {
  border-color: black;
}

.navbar-collapse.show {
  background-color: white !important;
}

.navbar-collapse {
  background-color: white !important;
  padding: 15px;
}

.bg-white {
  background-color: white !important;
}

.landing-page-wrapper {
  min-height: calc(100vh - $header-height);
  padding: 0 50px;
  margin-bottom: 100px;
}

.landing-page-btn {
  background-color: $primary;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  width: 230px;
}

.landing-modules-heading {
  font-size: 28px;
  font-weight: 800;
}

.landing-page-btn-outsource {
  background-color: $primary;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  width: 230px;
}

.landing-search-input {
  background-color: $primary;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  width: 100%;
}

.landing-search-input input {
  background-color: $primary;
  border: none;
  color: #fff;
  width: 100%;
}

.landing-search-input input::placeholder {
  color: #fff;
  font-weight: bolder;
  font-size: 1rem;
}

.landing-module {
  width: 100%;
  padding: 0 50px !important;
}

.search-jobs-icon {
  width: 20px;
  height: 20px;
}

.outsource-actions {
  align-items: center;
}

.landing-main-row {
  // margin-top: 3rem;
  min-height: calc(100vh - 60px);
}

.landing-main-col {
  margin-top: 3rem;
}

.tooltip-inner {
  background-color: $gray-100 !important;
  border: 1px solid $primary !important;
  color: $primary !important;
  font-size: 14px !important;
}

@media screen and (min-width: 769px) {
  .landing-module {
    width: 340px;
  }

  .landing-modules-heading {
    font-size: 30px;
    font-weight: 800;
  }
}

@media screen and (min-width: 1200px) {
  .outsource-actions {
    flex-direction: row !important;
    justify-content: center;
  }

  .landing-page-btn-outsource {
    width: 100%;
  }

  .landing-modules-heading {
    font-size: 35px;
    font-weight: 500;
  }

  .landing-layout-row {
    margin-top: 3rem;
  }

  .landing-main-row {
    margin-top: 3rem;
  }
}

.search-close-icon-wrapper {
  border-right: 1px solid white;
  padding-right: 10px;
}

.search-close-icon {
  width: 20px;
  height: 20px;
}

//Remove-scrollbar for chrome
.page-content::-webkit-scrollbar // .filters-section::-webkit-scrollbar,

// .jobs-list-section::-webkit-scrollbar,
// .job-detail::-webkit-scrollbar
  {
  display: none !important;
}

//Remove-scrollbar for IE & Firefox
.page-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// .page-content {
//   background-color: #f3f2f1 !important;
// }

@media screen and (min-width: 1200px) and (max-height: 800px) {
  .first-landing-column {
    height: 70vh;
  }
}

@media screen and (min-width: 1200px) and (min-height: 800px) {
  .landing-layout-row {
    margin-top: 0 !important;
  }
}

.dropdown-toggle::after {
  margin-left: 8px;
}

.nav-menu-item {
  font-size: 18px;
  font-weight: 500;
  color: $primary;
  cursor: pointer;
  border-bottom: 2px solid #fff;
}

.nav-menu-item:hover {
  background-color: rgba($primary, 1);
  border-radius: 5px;
  color: #fff;
}

.nav-menu-item-active {
  // color: $secondary;
  background-color: rgba($primary, 1);
  border-radius: 5px;
  color: #fff;
}

.nav-main-items {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.nav-dropdown-items {
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  cursor: pointer;
}

.nav-dropdown-items:hover {
  background-color: rgba($primary, 0.1);
}

.counsellor-modal-img {
  width: 230px;
  overflow: hidden;
  border-radius: 50px;
  height: 250px !important;

  img {
    width: 230px;
    height: 250px;
    object-fit: cover;
  }
}

// body {
//   // height: 300px;
//   // overflow-y: scroll;
//   /* Customize scrollbar width and color */
//   scrollbar-width: thin !important;
//   scrollbar-color: #f602cd #00ef34 !important;
// }

// /* Style for the scrollbar track */
// body::-webkit-scrollbar-track {
//   background-color: #eded09 !important;
// }

// /* Style for the scrollbar handle or thumb */
// body::-webkit-scrollbar-thumb {
//   background-color: #eb0707 !important;
// }

// /* Style for the scrollbar corner */
// body::-webkit-scrollbar-corner {
//   background-color: #0606f5 !important;
// }

body::-webkit-scrollbar,
.filters-section::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track,
.filters-section::-webkit-scrollbar-track {
  background: #eee;
}

body::-webkit-scrollbar-thumb,
.filters-section::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 50px;
}

.form-check-input:checked[type="radio"] {
  background-image: none !important;
}

input[type="time"] {}

// .styled-mui-table {
//   .css-1ag9q10 {
//     .css-i4bv87-MuiSvgIcon-root {
//       fill: #fff !important;
//     }
//   }
// }
.next-schedule-badge {
  background-color: $primary;
  // background-color: #f3f2f1;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.flatpickr-day {
  .selected {
    background-color: $secondary !important;
    border-color: $secondary !important;
  }
}

.approval-dropdown {
  .approve-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  li {
    cursor: pointer;
  }

  p {
    font-size: 14px;
  }

  button {
    border: 1px solid $primary;
  }
}

.ql-container:disabled {
  background-color: #f2f2f2;
}

// .text-danger {
//   font-size: 11px !important;
// }
// .css-80pr5n-MuiPaper-root {
//   box-shadow: none !important;
//   border: 2px solid $primary;
// }
.table-action-icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multistep-content-wrapper {
  min-height: 400px !important;
  // overflow-y: scroll !important;
  padding: 10px;
}

@media only screen and (min-width: 568px) {
  .multistep-content-wrapper {
    padding: 20px;
  }

  .profile-completion-column {
    border-radius: 5px;
  }
}

//Remove-scrollbar for chrome
.multistep-content-wrapper::-webkit-scrollbar {
  display: none !important;
}

//Remove-scrollbar for IE & Firefox
.multistep-content-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.suggestion-select {
  padding: 2.8px 0 2.8px 4px !important;
  border-color: #3e6a9f !important;

  svg {
    color: #3e6a9f;
  }
}

.pills {
  background: #3e6a9f30;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #3e6a9f;
}

.rich-textareas {
  .quill {
    border: none !important;
  }
}

.job-details-description ul {
  list-style-type: disc;
}

.job-details-description li {
  margin-left: 20px !important;
}

.accordion-item ul {
  margin-left: 20px !important;
  list-style-type: disc !important;
}

.accordion-item p {
  font-size: 15px !important;
}

@media only screen and (min-width: 992px) {
  .tabs-separater {
    border-right: 1px solid #000;
  }
}

.jobs-list-wrapper {
  min-height: calc(100vh - 150px);
  max-height: calc(100vh - 150px);
  height: 100% !important;
  overflow-y: scroll;

  // &::-webkit-scrollbar,
  // .filters-section::-webkit-scrollbar {
  //   width: 5px;
  // }

  // &::-webkit-scrollbar-track,
  // .filters-section::-webkit-scrollbar-track {
  //   background: #eee;
  // }

  &::-webkit-scrollbar {
    display: none !important;
  }

  //Remove-scrollbar for IE & Firefox
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .job-list-item {
    border: 1px solid #dadada;
    background-color: #f2f2f2;
    color: $primary;
    border-radius: 5px;
    cursor: pointer;

    // h5 {
    //   cursor: pointer;
    // }
  }

  // .job-list-item:hover {
  //   background-color: $primary;
  //   color: #fff !important;
  //   h5 {
  //     color: #fff !important;
  //   }
  // }
  .selected-job {
    background-color: $primary;
    color: #fff !important;

    h5 {
      color: #fff !important;
    }
  }

  .job-item-icon {
    cursor: pointer;
  }
}

.interview-details-table {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    background-color: #3e6a9f;
    color: white;
    padding: 8px;
    font-weight: bold;
  }

  td {
    border: 1px solid lightgrey;
    padding: 8px;
    text-align: left;
  }
}

.candidate-profile-sidebar {
  .offcanvas {
    --bs-offcanvas-width: 600px !important;
  }
}

.custom-quill .ql-container {
  border: 1px solid #3e6a9f !important;
  border-radius: 4px;
}

.custom-quill .ql-toolbar {
  border: 1px solid #3e6a9f !important;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.view-job-details {
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  height: 100% !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }

  //Remove-scrollbar for IE & Firefox
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.view-job-interviews {
  min-height: calc(100vh - 198px);
  max-height: calc(100vh - 198px);
  height: 100% !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }

  //Remove-scrollbar for IE & Firefox
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .view-job-interviews-btn {
    position: relative;

    .hired-list-div {
      position: absolute;
      right: 10px;
      top: 40px;
      z-index: 3;

      .hired-list {
        text-align: right;
        border: 1px solid #000;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;

        li {
          background-color: #c3d1e2;

          &:hover {
            cursor: pointer;
          }

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .table-cell-body {
    input.MuiInputBase-input {
      &::before {
        border-bottom: none !important;
      }
    }
  }

  .view-job-interviews-wrapper {
    .view-job-interviews-btn {
      position: relative;

      .hired-list-div {
        position: absolute;
        right: 10px;
        top: 40px;
        z-index: 3;

        .hired-list {
          text-align: right;
          border: 1px solid #000;
          background: #fff;
          border-radius: 5px;
          overflow: hidden;

          li {
            background-color: #c3d1e2;
            padding-right: 5px;

            &:hover {
              cursor: pointer;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1416px) {
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
  }
}

// .modal-open {
//   overflow-y: scroll !important;
// }
.css-1f9dmj-MuiTableCell-root:hover,
.css-c73ei6-MuiTableCell-root:hover,
.css-151bfr1-MuiTableCell-root:hover,
.css-1602lc0-MuiTableCell-root:hover,
.css-1h0dw4u-MuiTableCell-root:hover,
.css-1xroys0-MuiTableCell-root:hover,
.css-nwr8ng-MuiTableCell-root:hover,
.css-gaa5mz-MuiTableCell-root:hover,
.css-o6a5rj-MuiTableCell-root:hover,
.css-1oruxvl-MuiTableCell-root:hover {
  outline: none !important;
}

td:hover {
  outline: none !important;
}

.password-input {
  border: none;
  padding: 0;
  width: 100%;
}

.custom-phone-input {
  .PhoneInputInput {
    border: none;
  }
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.6rem 0.9rem 0.8rem 0.9rem !important;
}

.offcanvas {
  &::-webkit-scrollbar {
    display: none !important;
  }

  //Remove-scrollbar for IE & Firefox
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.candidate-card {
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .candidate-info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
  }

  .bg-custom-light {
    background-color: #abe7f2;
  }
}

.cover-document-selection {
  border: 1px solid $gray-300;
  border-radius: 10px;
  overflow: hidden;

  .bg-grey {
    background-color: $gray-300 !important;
  }

  .file-extension {
    width: 60px;
    height: auto;
  }
}

.close-upload-cover {
  width: 25px;
  height: 25px;
}

.candidate-detail-section {
  // font-family: $font-family-secondary;
  overflow-y: scroll;

  i {
    // font-size: 1.2rem;
  }

  height: calc(100vh - calc(3 * $header-height));

  @media (max-width: 992px) {
    &.filters-section {
      height: 50px;
    }
  }

  .job-detail {
    background-color: white;
  }

  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0;

  .cover-photo {
    position: relative;
    top: 0;
    left: 0;
    border-radius: 5px 5px 0 0;
    width: 100%;
  }

  .logo {
    position: absolute;
    bottom: 0;
    left: 40px;
    width: auto;
    height: 80px;
  }

  // h4 {
  //   font-weight: $font-weight-bold;
  // }

  th {
    // font-size: 1.1rem;
    // font-weight: $font-weight-bolder;
  }

  // p,
  // td,
  // .responsibilities {
  //   // font-size: 1rem;
  //   // font-weight: $font-weight-normal;
  //   list-style-position: outside;
  // }

  Table {
    width: 100%;
  }
}

.custom-searchbar-view-jobs {
  width: 100%;
  border: 1px solid #787676;
  border-radius: 5px;
  padding: 5px 10px;
}

.post-job-questionnaire {
  border: 1px solid $primary;
  border-radius: 5px;
  padding: 10px;

  .post-job-questionnaire-input {
    border: none;
    padding: 0;
  }
}

.text-dim-grey {
  color: #474c50;
}

.fw-500 {
  font-weight: 500 !important;
}

.unread-notification {
  background-color: rgba(#dadada, 0.45) !important;
}



.landing-section {
  min-height: calc(100vh - $header-height);

  .btn-white {
    border: white;
    color: #3e6a9f !important;
    box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 67%);
    background: white;

    &:hover {

      background: white;
      box-shadow: none;
    }
  }

  .features {
    img {
      width: 80px;
    }

    // .profile{
    //   width: 68px !important;
    // }

    .startup {
      width: 102px !important;
    }

    h3 {
      color: $primary;
      font-size: 1rem;
      font-weight: bold;
      margin-top: 15px;
    }
  }

  .btn-primary {
    box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 67%);

    &:hover {
      box-shadow: none;
    }
  }

  img {
    width: 60%;
  }

  h1 {
    font-size: 3rem;
  }

  .recruitment,
  .outsourcing {
    background-color: rgba($primary, 0.2);
  }

  input[type='checkbox'] {
    cursor: pointer;
  } 



  @media screen and (max-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }

    .features {
      img {
        margin-top: 10px;
        width: 40px;
      }

      // .profile{
      //   width: 40px !important;
      // }

      .startup {
        width: 50px !important;
      }

      h3 {
        font-size: .8rem;
        margin-top: 5px;
      }
    }

  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 2rem;
    }

  }

}


// ------------------------------- Dashobard --------------------------------
.active-tab {
  background-color: #3e6a9f;

  .active-tab-title {
    color: #fff;
  }
}

// ------------------------------- Dashobard end --------------------------------
